<template>
  <v-layout wrap>
    <v-flex xs12 pa-2 style="color: black; font-size: 25px" class="text">
      <span>User List</span>
    </v-flex>
  
    <v-flex pt-2 xs12>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text" style="color: black; font-size: 15px">Name</th>
              <th class="text" style="color: black; font-size: 15px">E-mail</th>
              <th class="text" style="color: black; font-size: 15px">Phone Number</th>
              <th class="text"  style="color: black; font-size: 15px">Outlet Associations</th>
              <th> </th>
            </tr>
          </thead> 
          <tbody>
            <tr v-for="(item, i) in list" :key="i">
              <td>
                <router-link
                  :to="'/DetailedViewPage?id=' + item._id"
                  style="text-decoration: none"
                >
                  <span class="text"
                    >{{ item.firstName }}{{ item.lastName }}
                  </span>
                </router-link>
              </td>
              <td>
                <router-link
                  :to="'/DetailedViewPage?id=' + item._id"
                  style="text-decoration: none"
                  ><span class="text">{{ item.email }}</span></router-link
                >
              </td>
              <td>
                <router-link
                  :to="'/DetailedViewPage?id=' + item._id"
                  style="text-decoration: none"
                  ><span class="text">{{ item.phone }}</span></router-link
                >
              </td>
              <td>
                <router-link
                  :to="'/DetailedViewPage?id=' + item._id"
                  style="text-decoration: none"
                  ><span class="text">{{ item.media }}</span></router-link
                >
              </td>
              <td> 
                <v-btn
                        small
                        class="mr-2"
                        outlined
                        block
                        @click="(deletedialog=true)(curid=item._id)"
                        color="red"
                      >
                        Delete
                      </v-btn>
              
                    </td>
            </tr>
          </tbody>
      
        </template>
      </v-simple-table>
    </v-flex>
    <v-dialog v-model="deletedialog" max-width="600px">

<v-card>
  <v-card-title
    >Are you sure you want to delete this
    User?</v-card-title
  >
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
      color="blue darken-1"
      text
      @click="deletedialog = false"
      >Cancel</v-btn
    >
    <v-btn
      color="blue darken-1"
      text
      @click="deleteItem()"
      >OK</v-btn
    >
    <v-spacer></v-spacer>
  </v-card-actions>
</v-card>
</v-dialog>
    <v-layout wrap>
      <v-flex xs12>
        <v-pagination
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :length="pages"
          :total-visible="7"
          v-model="currentpage"
          color="green"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </v-layout>
</template>


<script>
import axios from "axios";
export default {
  data() {
    return {
      link: null,
      page: 1,
      currentpage: 1,
      limit: 10,
      pages: 0,
      list: [],
      curid:null,
      deletedialog:false,
    };
  },
  watch: {
    currentpage() {
      // this.getData();
      this.getlist();
    },
  },

  mounted() {
    this.getlist();
  },

  methods: {
    deleteItem() {
  axios({
    url: "/media/admin/remove/user",
    method: "POST",
     data: {
      id: this.curid,
    },
    headers: {
      "token": localStorage.getItem("token"),
    },
  })
    .then((response) => {
      this.appLoading = false;
      if (response.data.status==true) {
        this.msg = response.data.msg;
        this.showSnackBar = true;
        this.deletedialog=false,
        this.getlist();
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      this.appLoading = false;
      this.ServerError = true;
      console.log(err);
    });
},
    getlist() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/media/admin/user/list",
        params: {
          page: this.currentpage,
          limit: this.limit,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>




